<template>
  <div>
    <b-row>
      <!-- Search -->
      <b-col
        cols="12"
        class="mt-2 mb-4"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchValue"
            class="d-inline-block mr-1"
            placeholder="Filtrar..."
            @keyup="filtro($event, 'title', filterGlobal)"
          />
        </div>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <div
        v-for="item in courses_filtred"
        :key="item.id"
        style="margin: 5px;"
      >
        <a
          v-if="isSpecial || order.some(e => e.course_id === item.id && (e.transaction_status === 3 || e.transaction_status === 4))"
          :href="`/cursos/${item.slug}/aula`"
        >
          <b-card
            :title="item.title"
            img-alt="Image"
            :img-src="`${thumb}/storage/course/thumb/${item.thumb}`"
            img-top
            tag="article"
            md="4"
            style="max-width: 20rem;display:block;margin: 0 auto;"
          >
            <b-overlay
              :show="loading"
              rounded="sm"
            >
              <b-button
                v-if="isSpecial || order.some(e => e.course_id === item.id && (e.transaction_status === 3 || e.transaction_status === 4))"
                :href="`/cursos/${item.slug}/aula`"
                variant="outline-primary"
                style="display: none"
                block
              >
                Acessar
              </b-button>
              <b-button
                v-else
                :href="`/curso/${item.slug}`"
                variant="outline-primary"
                block
                :hidden="!isOnCart(item.id)"
              >
                Comprar
              </b-button>

              <b-button
                variant="danger"
                block
                :hidden="isOnCart(item.id)"
                @click="removeItem(item)"
              >
                Remover pedido
              </b-button>
            </b-overlay>
          </b-card>
        </a>
        <a
          v-else
          :href="`/curso/${item.slug}`"
        >
          <b-card
            :title="item.title"
            img-alt="Image"
            :img-src="`${thumb}/storage/course/thumb/${item.thumb}`"
            img-top
            tag="article"
            md="4"
            style="max-width: 20rem;display:block;margin: 0 auto;"
          >
            <b-overlay
              :show="loading"
              rounded="sm"
            >
              <b-button
                :href="`/curso/${item.slug}`"
                variant="outline-primary"
                block
                :hidden="!isOnCart(item.id)"
              >
                Comprar
              </b-button>

              <b-button
                variant="danger"
                block
                :hidden="isOnCart(item.id)"
                @click="removeItem(item)"
              >
                Remover pedido
              </b-button>
            </b-overlay>
          </b-card>
        </a>
      </div>
    </b-row>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import FormRatingBorderlessVue from '@/views/forms/form-element/form-rating/FormRatingBorderless.vue'
import CoursesServices from './coursesServices'
import store from '@/store'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      courses: [],
      courses_filtred: [],
      thumb: process.env.VUE_APP_API_BASE,
      loading: false,
      isSpecial: null,
      ordVerify: [],
      searchValue: null
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.sessionInfo.user
    },
    items() {
      return this.$store.state.payment.items
    },
    order() {
      return this.$store.state.payment.order
    },
  },
  async mounted() {
    await this.ActionGetOrder()
    this.ordVerify = this.order
    CoursesServices().getCourses().then(api => {
      this.courses = api.data.response.courses
      this.courses_filtred = this.courses
    })
    // eslint-disable-next-line prefer-const
    let { items } = this
  },
  methods: {
    filtro(e, propriedadePesquisa, filterGlobal) {
      if (e.target.value.trim() !== '') {
        const lista = this.courses
        const result = filterGlobal(e, lista, propriedadePesquisa)
        this.courses_filtred = result
      } else {
        this.courses_filtred = this.courses
      }
    },
    ...mapActions('payment', [
      'ActionRemoveItem',
      'ActionGetOrder'
    ]),
    goToUrl(congress, slug) {
      window.location.href = `/congress/${congress}/${slug}`
    },
    isOnCart(id) {
      return !this.items.some(e => e.course_id === id)
    },
    async removeItem(item) {
      this.item = null
      // eslint-disable-next-line array-callback-return
      this.items.some(e => {
        if (e.id === item.id) {
          this.item = e
        }
      })
      await this.ActionRemoveItem(this.items.indexOf(this.item))
      localStorage.setItem('items', JSON.stringify(this.items))
    },
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    order() {
      this.ordVerify = this.order
    },
    ordVerify() {
      if (
        this.user.role === 'ADMIN' ||
        this.user.role === 'MASTER'
      ) {
        this.isSpecial = true
      } else {
        this.isSpecial = false
      }
    },
    isSpecial() {
      this.loading = false
    },
  }
}
</script>

<style>
#notification {
  list-style: none;
  margin: 10px 10px;
}

#notification li {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0;
}

#notification li button {
  margin-left: auto;
}
</style>
